article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block
}

audio, canvas, video {
  //display: inline-block;
  display: inline;
  zoom: 1;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html, button, input, select, textarea {
  font-family: sans-serif;
  font-family: 'MyriadPro', sans-serif;
}

html, body {
  overflow-x: hidden;
  width: 100%;
}

body {
  font-size: 21px;
  margin: 0;
  font-family: MyriadPro, sans-serif;
}

html, html a {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}

a:active, a:hover {
  outline: 0;
  text-decoration: none;
}

header {
}

h1 {
  font-family: MyriadProLight, sans-serif;
  font-size: 2.190em;
  font-size: 46px;
}

h2 {
  font-family: MyriadPro, sans-serif;
  font-size: 1.238em;
  font-size: 26px;
}

h3 {
  font-family: MyriadPro, sans-serif;
}

h4 {
}

h5 {
}

h6 {
}

p {
  font-family: MyriadPro, sans-serif;
}

abbr[title] {
  border-bottom: 1px dotted
}

b, strong {
  font-weight: bold
}

blockquote {
  margin: 0px
}

dfn {
  font-style: italic
}

mark {
  background: #ff0;
  color: #000;
}

p, pre {
  margin: 1em 0
}

code, kbd, pre, samp {
  font-size: 1em;
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none
}

q:before, q:after {
  content: '';
  content: none;
}

small {
  font-size: 75%
}

sub, sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

dl, men, ol {
  margin: 1em 0
}

dd {
  margin: 0 0 0 40px
}

menu, ol, ul {
  padding: 0
}

nav ul, nav ol {
  line-height: 22px;
  list-style: none;
  list-style-image: none;
}

.main_menu ul.list {
  display: inline;
  list-style-type: none;
  overflow: hidden;
}

.main_menu .second_menu {
  margin-top: 30px;
}

.main_menu li.list {
  display: inline;
  float: right;
  margin-bottom: 20px;
}

#languages_panel {
  display: inline-block;
}

.main-menu li.list a {
  display: block;
  color: #c97569;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.main-menu li a:hover:not(.active) {
  background-color: #ccc;
}

.active {
}

.main_langpanel, .main_menu, .main_toggle {
  display: inline-block;
}

.add_camera_mob a {
  color: #fff;
  font-family: MyriadProSemibold, sans-serif;
  font-size: 14px;
  background: #a62189;
  text-transform: uppercase;
  padding: 10px 5px;
}

.add_camera_mob a:hover {
  background: #7c1866;
}

.view_demo_mob a {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 14px;
  color: #00aae3;
  text-transform: uppercase;
  margin-right: 16px;
  padding: 10px 5px;
  border: 1px solid #007fa9;
}

.view_demo_mob a:hover {
  background: #7c1866;
  border: none;
  color: #fff;
}

.add_camera_pc a {
  border-radius: 3px;
  font-family: MyriadProSemibold, sans-serif;
  font-size: 15px;
  color: #fff;
  background: #a62189;
  text-transform: uppercase;
  padding: 6.5px 10px;
}

.add_camera_pc a:hover {
  background: #7c1866;
}

.view_demo_pc a {
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  margin-right: 36px;
  padding: 6px 10px;
  background: #00aae3;
  border-radius: 3px;
}

.view_demo_pc a:hover {
  background: #007fa9;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 0
}

form {
  margin: 0
}

fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

legend {
  padding: 0;
  border: 0;
  white-space: normal;
  margin-left: -7px;
}

button, input, select, textarea {
  font-family: MyriadProLight, sans-serif;
  color: #787878;
  border: none;
  background: none;
  margin: 0;
  font-size: 100%;
  vertical-align: middle;
}

.modal-body select {
  border-radius: 3px;
  border: 1px solid #ccc;
  padding: 5px;
}

button, input {
  line-height: normal
}

button, html input[type="button"],
input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  overflow: visible;
  border-radius: 6px;
}

button[disabled], input[disabled] {
  cursor: default
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  height: 13px;
  width: 13px;
}

input[type="search"] {
  box-sizing: content-box;
  -webkit-appearance: textfield;
  nowhitespace: afterproperty;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

button::-moz-focus-inner, input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button[type="submit"] {
  height: 50px;
  width: 160px;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

a {
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-property: color, border-color, background-color;
  transition-property: color, border-color, background-color;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

p {
  font-size: 21px;
  font-size: 1.0em;
  line-height: 25px;
}

a {
  color: #73d0da
}

a:hover, a:focus {
  text-decoration: none;
}

.texture-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.texture-partnership {
  position: relative;
  top: -80px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.texture-partnership img {
  width: 70%;
}

.use-btn {
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 20px 50px;
  border-radius: 3px;
  background-color: #fff;
  color: #4b98a9;
  font-size: 16px;
}

.use-btn:hover, .use-btn:focus {
  background-color: #73d0da;
  color: #fff;
  text-decoration: none;
}

.blue-btn, .magenta-btn {
  display: inline-block;
  padding: 9px 20px;
  color: #fff;
}

.magenta-btn span {
  font-size: 16px;
}

.blue-btn span {
  font-size: 16px;
}

.fa {
  vertical-align: middle;
}

.fa-angle-right:before {
  padding-left: 5px;
}

.fa-plus:before {
  padding-right: 5px;
}

.easy-magenta-btn {
  margin-bottom: 10px;
}

.blue-btn {
  background: #00aae3;
  line-height: 1.0;
}

.case-btn {
  min-width: 182px;
}

.price-btn {
  max-width: 182px;
}

.magenta-btn {
  background: #a62189;
  line-height: 1.0;
}

.magenta-btn-head {
  font-family: MyriadProSemibold, sans-serif;
  padding: 19px 20px;
}

.blue-btn-head {
  font-family: MyriadProSemibold, sans-serif;
  padding: 20px 20px;
}

.add-camera-btn {
  margin-left: 20px;
}

.big-btn {
  text-transform: uppercase;
}

.readmore-btn {
  margin-left: 97px;
}

.blue-btn:hover {
  color: #fff;
  background: #007fa9;
}

.magenta-btn:hover, .easy-magenta-btn:hover {
  color: #fff;
  background: #7c1866;
}

.learn-btn:hover, .download-btn:hover, .learn-btn:focus, .download-btn:focus {
  text-decoration: none;
}

.read-more-btn {
  display: inline-block;
  color: #323a45;
  text-transform: uppercase;
  font-weight: 400;
}

.read-more-btn i, .download-btn i {
  margin-left: 5px
}

.showcase .download-btn {
  margin-top: 50px
}

.download .download-btn {
  margin-top: 25px
}

span.note {
  padding-left: 20px;
  vertical-align: middle;
  display: inline-block;
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
}

.parent_btn {
  cursor: pointer;
  transition: 1s;
  display: table;
  padding: 0 20px;
  font-family: MyriadPro, sans-serif;
  font-size: 16px; /*border-radius:6px;*/
  border-radius: 40px;
  color: #fff;
}

.parent_h_btn {
  cursor: pointer;
  transition: 1s;
  display: table;
  padding: 0 10px;
  font-family: MyriadPro, sans-serif;
  font-size: 14px;
  border-radius: 3px;
}

.magenta_btn {
  background: #a62189;
}

.magenta_btn:hover {
  background: #7c1866;
}

.blue_btn {
  background: #00aae3;
}

.blue_btn:hover {
  background: #007fa9;
}

.gray_btn {
  border: 1px solid #646464;
  border-radius: 40px;
}

.gray_btn:hover {
  border: 1px solid #a42187;
  border-radius: 40px;
  background: #a42187;
}

.gray_p_btn {
  background: #787878;
}

.gray_p_btn:hover {
  background: #646464;
}

.big_btn {
  text-transform: uppercase;
}

.white_btn {
  color: #fff;
}

.h_btn_60 {
  height: 60px;
}

.w_btn_198 {
  text-align: center;
  width: 216px;
}

.h_btn_52 {
  height: 52px;
}

.h_btn_32 {
  height: 32px;
}

.header_btn {
  width: 160px;
  text-align: center;
}

.solution_w span {
  width: 169px;
}

.center_btn {
  margin: 0 auto;
}

.center_t_btn {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .need_btn {
    background: #a62189;
  }
  .need_btn:hover {
    background: #7c1866;
  }
}

.inline_btn {
  display: inline-block;
}

.inline_guide_btn {
  display: inline-block;
  margin: 0 20px;
}

.guide_btn {
  width: 258px;
}

.want_btn i {
  float: right;
}

.want_btn span {
  padding-top: 5px;
}

.registr_btn {
  border: 1px solid #00aae3;
}

.registr_btn:hover {
  border: 1px solid #7c1866;
  background: #7c1866;
  color: #fff;
}

.registr_btn a:hover {
  color: #fff;
}

a .registr_btn {
  color: #00aae3;
}

.login_btn {
  border: 1px solid #646464;
}

.login_btn:hover {
  border: 1px solid #7c1866;
  background: #7c1866;
  color: #fff;
}

.login_btn a:hover {
  color: #fff;
}

a .login_btn {
  color: #646464;
}

.margin_r_btn {
  margin-right: 20px;
}

.note_btn {
  color: #fff;
  opacity: 0.5;
}

.need_btn {
  margin-left: 97px;
}

@media screen and (max-width: 465px) {
  .need_btn {
    margin: 0 auto;
  }
}

.child_btn, .child_h_btn, .child_r_btn, .child_l_btn {
  display: table-cell;
  vertical-align: middle;
}

.child_btn a, .child_h_btn a, .child_l_btn a {
  color: #fff;
  display: inline;
}

.child_r_btn a {
  color: #00aae3;
  display: inline;
}

.child_l_btn a {
  color: #646464;
  display: inline;
}

.child_btn span, .child_h_btn span, .child_r_btn span, .child_l_btn span {
  display: inline-block;
  vertical-align: middle;
}

.child_btn i {
  padding-left: 20px;
}

.mrg_add_cam {
  margin-right: 65px;
}

.mrg_demo {
  margin-right: 31px;
}

.mrg_demo_2 {
  margin-right: 24px;
}

.nav-toggle {
  margin-top: 25px;
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 999999;
  padding: 10px 35px 16px 0px;
  cursor: pointer;
}

.nav-toggle:focus {
  outline: none;
}

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  content: '';
  position: absolute;
  display: block;
  width: 35px;
  height: 4px;
  border-radius: 1px;
  background: #646464;
  cursor: pointer;
}

.nav-toggle span:before {
  top: -13px
}

.nav-toggle span:after {
  bottom: -13px
}

.nav-toggle span, .nav-toggle span:before, .nav-toggle span:after {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.nav-toggle.active span {
  background-color: transparent
}

.nav-toggle.active span:before, .nav-toggle.active span:after {
  top: 0
}

.nav-toggle.active span:before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-toggle.active span:after {
  top: 10px;
  -webkit-transform: translateY(-10px) rotate(-45deg);
  -ms-transform: translateY(-10px) rotate(-45deg);
  transform: translateY(-10px) rotate(-45deg);
}

.navicon {
  position: relative;
  height: 26px;
}

.navicon p {
  margin: 1px 50px 0 0
}

.navicon-fixed {
  position: fixed;
  top: 59px;
  right: 143px;
}

.hero {
  position: relative;
  padding-top: 20px;
  background: rgb(40, 70, 102) url('../assets/images/header_main.jpeg') no-repeat center center;
  background-size: cover;
  color: #fff;
}

.mb-5 {
  margin-bottom: 2rem;
}

.hero-content img {
  width: 100%;
}

.hero_mini {
  padding: 20px 0 60px 0;
  min-height: 170px;
  border-bottom: 1px solid #00aae3;
}

.hero h1 {
  margin: 10px 0;
  color: #646464;
  font-size: 1.8rem;

  @media screen and (min-width: 639px) {
    margin: 30px 0;
    font-size: 3rem;
  }
}

.mobile_store {
  display: inline-block;

  &.mr {
    margin-right: 10px;
  }

  img {
    height: 50px;
  }
}

.hero h1 span {
  display: inline-block;
  color: #a1a9b0;
}

#home {
  width: 100%;
  height: 100%;
}

.hero {
  width: 100%;
  height: 100%;
}

.cloud_head {
  padding: 30px 0;
  background: rgb(40, 70, 102) url('../assets/images/header_bg.jpeg') no-repeat top center;
  background-size: cover;
}

.cloud_head .col-md-12 {
  margin-top: -20px;
}

header i {
  margin-left: 5px
}

section.video i {
  margin-right: 10px;
  color: #323a45;
  vertical-align: middle;
  font-size: 50px;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}

section.video h1 {
  font-weight: 400;
  font-size: 20px;
}

section.video {
  padding: 60px 0;
  background-color: #f6f7f9;
}

section.video a {
  color: #323a45
}

section.video a:hover, section.video a:focus {
  color: #73d0da;
  text-decoration: none;
}

section.video a:hover i, section.video a:focus i {
  color: #73d0da
}

.flickity-page-dots .dot {
  width: 13px;
  height: 13px;
  opacity: 1;
  background: transparent;
  border: 2px solid white;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
}

.flickity-page-dots .dot.is-selected {
  background: white;
}

.features-bg {
  position: relative;
  min-height: 400px;
  //background: url('../img/features-intro-01.jpg') no-repeat center center;
  background-size: cover;
}

.features-img {
  width: 100%;
  height: 400px;
  text-align: center;
  line-height: 400px;
}

.features-slider {
  position: relative;
  padding: 11% 100px 10% 100px;
  height: 400px;
  background-color: #3F6184;
}

.features-slider ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.features-slider ul li {
  width: 100%;
}

.features-slider li h1 {
  margin-bottom: 15px;
  color: #fff;
  font-weight: 400;
  font-size: 22px;
}

.features-slider li p {
  color: #fff;
  font-size: 14px;
}

.features-intro-img {
  position: relative
}

.slides li h1 {
  margin: 0;
  padding: 0;
}

.features-slider .flickity-page-dots {
  text-align: left;
  margin-top: 50px;
  position: static;
}

.features-slider .flickity-page-dots .dot {
  margin: 0 12px 0 0;
}

.features-list {
  padding: 40px 0;
  background: url('../assets/images/3_bg.jpeg') no-repeat center center;
  background-size: cover;
  color: #fff;
}

.features-list p {
  //font-family: MyriadPro, sans-serif;
  //margin-bottom: 0;
  //font-size: 16px;
}

@media screen and (max-width: 465px) {
  .adv_btn {
    margin: 10px auto;
  }
}

.features_block {
  text-align: center;
  margin-top: 20px;
}

.features {
  text-align: center;
  vertical-align: top;
}

.feature-content {
  vertical-align: middle;
  display: inline-block;
  margin-left: 20px;
}

.needyou-content {
  vertical-align: middle;
  display: inline-block;
  margin-left: 20px;
  width: 75%;
}

.needyou-content p {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  margin-bottom: 20px;
  color: #fff !important;
}

.center-image {
  vertical-align: middle;
}

.feature-icon {
  display: inline-block;
  width: 77px;
  height: 77px;
  text-align: center;
  font-size: 25px;
  line-height: 77px;
}

.feature-icon i {
  color: #4e9ba3
}

.advantages_btn {
  margin-top: 15px;
}

.feature-block {
  margin: 15px 0;
}

.feature-2 {
  margin-left: -50px;
}

blockquote {
  margin: 40px 0 0;
  padding: 0;
  border: none;
}

blockquote p {
  display: inline-block;
  margin: 0;
  padding: 0;
  width: 70%;
  vertical-align: top;
  font-style: italic;
}

blockquote .avatar {
  display: inline-block;
  margin-right: 20px;
  width: 64px;
  height: 64px;
  vertical-align: middle;
}

blockquote .logo-quote {
  display: inline-block;
  margin: 0 0 0 90px;
}

.container {
  max-width: 1170px !important;
}

.showcase {
  background-size: cover;
}

.footer-nav {
  display: block;
  overflow: hidden;
  margin: 0;
}

.showcase_allforyou {
  background: url('../assets/images/8_bg.webp') no-repeat;
  background-size: cover;
}

.showcase_allforyou .allforyou_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  .showcase_allforyou {
  }
  .showcase_allforyou .allforyou_name {
    text-transform: uppercase;
  }
  .showcase_allforyou .blue-btn {
    background: #a62189;
  }
}

.showcase_motion {
  background: url('../assets/images/6_bg.jpeg') no-repeat;
  background-size: cover;
}

.showcase_buy {
  min-height: 500px;
}

.showcase-partnership {
  background: #fff url('../assets/images/partnership_bg.jpeg') no-repeat;
  background-size: cover;
}

.partnership_margin {
  margin-top: -40px;
}

.showcase h1 {
  margin: 0 0 20px 0;
  color: #277689;
  font-weight: 400;
  font-size: 22px;
}

.simplestart h1 {
  text-transform: uppercase;
  margin: 0 0 50px 0;
  color: #fff;
  font-weight: 400;
  font-size: 28px;
  text-align: center;
}

.showcase p {
  margin-bottom: 25px;
  color: #808080;
}

.device {
  position: absolute;
  top: -60px;
  left: 20%;
  min-height: 676px;
  width: 359px;
  //background: url('../assets/images/iphone-skeleton.png') no-repeat center center;
}

.about-motion {
  padding-top: 35px;
}

.device-content {
  position: absolute;
  top: 115px;
  left: 56px;
  width: 247px;
  height: 445px;
  background: rgba(0, 0, 0, 0.3);
}

.device-content img {
  width: 247px;
  height: 445px;
}

.showcase-wrap {
  position: relative;
  padding: 100px 0;
  min-height: 600px;
}

.showcase-wrap-allforyou {
  position: relative;
  padding: 20px 0;
}

.showcase-wrap-anydevice {
  background: #fafafa;
  position: relative;
  padding: 33px 0 40px 0;
  min-height: 400px;
}

.showcase-wrap-anydevice .anydevice_name_1 {
  display: block;
  font-family: MyriadProLight, sans-serif;
  font-size: 26px;
  color: #00aae3;
}

.showcase-wrap-anydevice .anydevice_name_2 {
  display: block;
  text-transform: uppercase;
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #00aae3;
}

.showcase-wrap-anydevice .anydevice_t {
  padding-left: 30px;
}

.showcase-wrap-anydevice ul {
  list-style: none;
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #787878;
  margin-top: 0;
}

.showcase-wrap-simplestart {
  text-align: center;
  position: relative;
  padding: 40px;
}

.showcase-wrap-simplestart .simplestart_name {
  font-size: 26px;
  font-family: MyriadProSemibold, sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.showcase-wrap-studies {
  position: relative;
  padding: 40px 0;
  min-height: 408px;
}

.showcase-wrap-studies .cases_name_1, .showcase-wrap-studies .cases_name_2 {
  display: block;
  color: #00aae3;
  font-size: 26px;
  text-transform: uppercase;
}

.showcase-wrap-studies .cases_name_1 {
  font-family: MyriadProSemibold, sans-serif;
}

.showcase-wrap-studies .cases_name_2 {
  font-family: MyriadProLight, sans-serif;
}

.showcase-wrap-studies ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #787878;
  list-style: none;
}

.showcase-wrap-partnership {
  padding: 40px;
  position: relative;
}

.showcase-wrap-partnership .container {
  margin-top: -40px;
}

.showcase-wrap-partnership ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #fff;
  list-style: none;
}

.showcase-wrap-motion {
  position: relative;
  padding-bottom: 30px;
  min-height: 400px;
}

.showcase-wrap-motion .motion_name {
  font-size: 26px;
  font-family: MyriadProSemibold, sans-serif;
  color: #00aae3;
  text-transform: uppercase;
}

.showcase-wrap-motion ul {
  list-style: none;
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #fff;
  margin-bottom: 40px;
}

.showcase-wrap-motion img {
  width: 100%;
}

.showcase-wrap-buy {
  padding: 20px 0 0 0;
}

.showcase-wrap-buy .buy_pc_name_1, .showcase-wrap-buy .buy_pc_name_2, .showcase-wrap-buy .buy_mob_name_2 {
  display: block;
  font-size: 26px;
  text-align: center;
  color: #a62189;
  text-transform: uppercase;
}

.showcase-wrap-buy .buy_pc_name_1 {
  font-family: MyriadProLight, sans-serif;
}

.showcase-wrap-buy .buy_pc_name_2 {
  margin-bottom: 120px;
  font-family: MyriadProSemibold, sans-serif;
}

.showcase-wrap-buy .buy_mob_name_2 {
  font-family: MyriadProSemibold, sans-serif;
}

.showcase-wrap-buy-mob {
  margin-bottom: 0 !important;
}

.block-buy {
  text-align: center;
  position: relative;
}

.block-buy .cameras {
  z-index: 5;
  width: 60%;
  position: relative;
}

.block-buy .line, .line-btn {
  position: absolute;
}

.block-buy .line span {
  font-size: 22px;
  color: #00aae3;
  font-family: MyriadProSemibold, sans-serif;
}

.mob span {
  font-size: 21px;
  color: #00aae3;
  font-family: MyriadProSemibold, sans-serif;
}

.row_mob {
  margin-bottom: 20px;
}

.row_mob_first {
  margin-top: 20px;
}

.block-buy .line1 {
  top: 20%;
  right: 69%;
}

.block-buy .line2 {
  top: -23%;
  left: 39%;
}

.block-buy .line3 {
  top: 0;
  right: 19%;
}

.block-buy .bullet {
  top: 11%;
  right: 80%;
}

.block-buy .robot {
  top: -27%;
  left: 26%;
}

.block-buy .cubic {
  top: -9%;
  right: 6%;
}

.block-buy .shop_now {
  position: absolute;
  bottom: 7%;
  right: 0;
}

.block-buy .shop_now_mob {
  margin: 20px auto;
}

.showcase-partnership {
  text-align: center;
}

.showcase-slider ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.showcase-slider .flickity-page-dots {
  bottom: 20px;
}

.cloudmaster {
  padding: 50px;
  background: url('../assets/images/cloudmaster_bg.jpeg') no-repeat center center;
  background-size: cover;
}

#cloudmaster_show, #cloudmaster_show2, #shop_show {
  display: none;
}

.cloudmaster p {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  text-align: center;
}

.cloudmaster .cloud-content span {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 21px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.cloud-content {
  padding: 21px 0;
  text-align: center;
}

.cloud-icon {
  text-align: center;

  img {
    max-height: 77px;
  }
}

.cloud-icon i {
  color: #4e9ba3
}

.download_cloud {
  text-align: center;
}

.simplestart {
  background: #00aae3;
}

.simplestart p {
  color: #fff;
  text-align: center;
}

.guide-step > div {
  padding: 0 5%;
}

.guide-step p {
  font-family: MyriadPro, sans-serif;
  font-size: 16px;
}

.guide img {
  width: 90%;
}

#guide {
  margin-top: 20px;
}

.guide_pc {
  margin: 40px 0;
}

.mobile_store img {
  margin-right: 3%;
}

.quickguide {
  padding: 40px 0;
  background: #00aae3;
  min-height: 190px;
  text-align: center;
}

.quickguide .quickguide_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  display: block;
  text-transform: uppercase;
  color: #fff;
}

.quickguide select {
  font-family: MyriadPro, sans-serif;
  color: #fff;
  border-bottom: 1px dashed #fff;
  font-size: 16px;
  text-transform: uppercase;
}

select:focus {
  outline: none;
}

.quickguide option {
  color: #00aae3;
  background: #fff;
}

.quickguide #pc, .quickguide #mob {
  margin: 40px 0;
}

.camera_manual {
}

.how_works img {
  width: 90%;
}

.online_cameras {
}

.price_intro {
  background: #00aae3;
  padding: 40px 0;
}

.columns, .inline {
  display: inline-block;
  margin: 10px;
}

.both_prices {
  background: #f0f0f0;
  background-size: cover;
  background-position-y: 272px;
  padding-top: 10px;
  padding-bottom: 40px;
  text-align: center;
}

.price_bg_1 {
  background: #f0f0f0;
  height: 200px;
}

.price_bg_2 {
  height: 567px;
}

.price_bg_3 {
  background: #787878;
  height: 150px;
}

.both_prices .price_cloud_name77, .both_prices .price_broadcast_name {
  font-family: MyriadProLight, sans-serif;
  text-transform: uppercase;
  color: #787878;
}

.price_cloud_block, .price_broadcast_block {
  display: inline-block;
  text-align: center;
}

@media screen and (max-width: 638px) {
  .price_cloud_block, .price_broadcast_block {
    margin-bottom: 20px;
  }
  .price-brown, .price-orange, .price-tomato {
    margin-bottom: 10px;
    border-radius: 15px;
  }
  .item-or {
    display: block;
  }
}

.price_broadcast_block {
  padding-bottom: 20px;
}

.price_cloud .price {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  vertical-align: middle;
  width: 200px;
}

@media screen and (min-width: 639px) {
  .price_cloud .price {
    margin: 0;
    padding: 0;
  }
}

.price_cloud .price-gray {
  background: #969696;
  border-radius: 15px;
  margin: 0 20px;
}

.price_cloud .price-brown {
  background: #885d4d;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
}

.price_cloud .price-orange {
  background: #ffa33c;
}

.price_cloud .price-tomato {
  background: #ff6963;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.price_cloud .price-blue {
  background: #7986cb;
  border-radius: 15px;
  margin: 0 20px;
}

.price_cloud .price ul {
  list-style-type: none;
}

.price_cloud .price li, .price_broadcast .price li {
  padding: 5px 0;
}

.price_broadcast .price {
  background: #fff;
  border-radius: 15px;
  width: 162px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}

.price_cloud .price span, .price_broadcast .price span {
}

.price_cloud .price .price_1 span {
  color: #008ed3;
}

.price_cloud .price .price_2 span {
  color: #fff;
}

.price_cloud .price .price_3 span {
  color: #4a5ab0;
}

.price_cloud .price .price_4 span {
  color: #6e419f;
}

.price_cloud .price .price_5 span {
  color: #94268e;
}

ul.price_h_1 {
  list-style: none;
  margin-top: 0;
  line-height: 1.0;
}

ul.price .price_pad {
  padding-top: 10px;
}

ul.price:last-child {
  padding-bottom: 10px;
}

.price_broadcast .price .price_1 span {
  color: #008ed3;
}

.price_broadcast .price .price_2 span {
  color: #1e79c5;
}

.price_broadcast .price .price_3 span {
  color: #3d63b7;
}

.price_broadcast .price .price_4 span {
  color: #5b4ea9;
}

.price_broadcast .price .price_5 span {
  color: #79399b;
}

.price_broadcast .price .price_6 span {
  color: #94268e;
}

.price_cloud .price .header, .price_broadcast .price .header {
  font-family: MyriadProSemibold, sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  height: 50px;
  color: #fff;
  font-size: 24px;
  line-height: 37px;
}

.price_cloud .price .header span, .price_broadcast .price .header span {
  font-family: MyriadProLight, sans-serif;
}

.price_cloud .prices, .price_broadcast .prices {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  font-size: 16px;
}

.price_cloud .prices span, .price_broadcast .prices span {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 21px;
}

.price_cloud p {
  font-family: MyriadProLight, sans-serif;
  font-size: 23px;
  color: #787878;
}

.price_cloud .price .header_1 {
  border-bottom: 3px solid #fff;
}

.price_cloud .price .header_2 {
  background: url('../assets/images/prices/price_cloud_2.png') no-repeat center center;
}

.price_cloud .price .header_3 {
  background: url('../assets/images/prices/price_cloud_3.png') no-repeat center center;
}

.price_cloud .price .header_4 {
  background: url('../assets/images/prices/price_cloud_4.png') no-repeat center center;
}

.price_cloud .price .header_5 {
  background: url('../assets/images/prices/price_cloud_5.png') no-repeat center center;
}

.price_cloud .price .header_6 {
  background: url('../assets/images/prices/price_cloud_6.png') no-repeat center center;
}

.price_cloud .price .header_7 {
  background: url('../assets/images/prices/price_cloud_7.png') no-repeat center center;
}

.price_cloud .price .header_8 {
  background: url('../assets/images/prices/price_cloud_8.png') no-repeat center center;
}

.price_cloud .price .header_9 {
  background: url('../assets/images/prices/price_cloud_9.png') no-repeat center center;
}

.price_cloud .price .header_10 {
  background: url('../assets/images/prices/price_cloud_10.png') no-repeat center center;
}

.price_cloud .price .header_11 {
  background: url('../assets/images/prices/price_cloud_11.png') no-repeat center center;
}

.price_cloud .price .header_12 {
  background: url('../assets/images/prices/price_cloud_12.png') no-repeat center center;
}

.price_broadcast {
  min-height: 200px;
}

.price_broadcast .price .header_1 {
  background: url('../assets/images/prices/price_broadcast_1.png') no-repeat center center;
}

.price_broadcast .price .header_2 {
  background: url('../assets/images/prices/price_broadcast_2.png') no-repeat center center;
}

.price_broadcast .price .header_3 {
  background: url('../assets/images/prices/price_broadcast_3.png') no-repeat center center;
}

.price_broadcast .price .header_4 {
  background: url('../assets/images/prices/price_broadcast_4.png') no-repeat center center;
}

.price_broadcast .price .header_5 {
  background: url('../assets/images/prices/price_broadcast_5.png') no-repeat center center;
}

.price_broadcast .price .header_6 {
  background: url('../assets/images/prices/price_broadcast_6.png') no-repeat center center;
}

.hr_prices {
  background: #00aae3;
  height: 1px;
  margin: 40px 0 20px 0;
}

.price-cloud-btn {
  text-align: center;
  color: #787878;
  font-family: MyriadProLight, sans-serif;
}

.price-btm-btn ul {
  list-style: none;
}

.price-btm-btn ul li {
  display: inline-block;
  margin: 0 10px;
}

.price-btm-btn ul li a {
  display: block;
  text-align: center;
}

@media screen and (max-width: 638px) {
  .price-btm-btn ul li {
    display: block;
    margin: 0;
  }
  .price-btm-btn ul li a {
    margin: 0 auto;
  }
}

.price-see-more, .item-or {
  text-transform: uppercase;
  font-size: 14px;
}

.like img {
  position: relative;
  bottom: 18px;
  left: 46px;
}

.like span {
  position: relative;
  left: 27px;
  bottom: 7px;
}

.online_cameras {
  margin: 20px 0;
}

.flag_wrapper {
  display: inline-block;
  margin-right: 5px;
}

.online-camera-info {
  color: #787878;
}

.online-camera-info a {
  color: #787878;
}

.online-camera-info a:hover {
  color: #787878;
  border-bottom: 1px dashed #787878;
}

.online_cameras_block {
  margin-bottom: 20px;
}

.flagtext {
  color: #787878;
  font-family: MyriadProLight, sans-serif;
  font-size: 18px;
  margin-left: 39px;
}

.interest_case {
  background: #00aae3;
  padding: 20px 0;
}

.interest_case .interest_name_1, .interest_case .interest_name_2 {
  display: block;
  color: #fff;
  font-size: 36px;
  text-align: center;
}

.interest_case .interest_name_1 {
  font-family: MyriadProLight, sans-serif;
}

.interest_case .interest_name_2 {
  font-family: MyriadProSemibold, sans-serif;
}

.interest_case .interest_name_2 span {
  text-transform: uppercase;
}

.about_case {
  background-size: cover;
  background: url('../assets/images/cases/5-3_bgr.jpeg') no-repeat;
  padding: 40px 0;
  display: block;
}

.about_case .about_case_name, .case_1 .case_1_name, .case_3 .case_3_name {
  font-size: 26px;
  font-family: MyriadProSemibold, sans-serif;
  color: #00aae3;
  text-transform: uppercase;
}

.case_2 .case_2_name, .case_4 .case_4_name {
  font-size: 26px;
  font-family: MyriadProSemibold, sans-serif;
  color: #fff;
  text-transform: uppercase;
}

.about_case p, .case_1 p, .case_3 p {
  font-size: 21px;
  font-family: MyriadProLight, sans-serif;
  color: #787878;
}

.case_2 p, .case_4 p {
  font-size: 21px;
  font-family: MyriadProLight, sans-serif;
  color: #fff;
}

.case_1, .case_2, .case_3, .case_4 {
  padding: 40px 0;
}

.case_1 {
  background: url('../assets/images/cases/5-4_bgr.jpeg') no-repeat;
  background-size: cover;
}

.case_2 {
  background: url('../assets/images/cases/5-5_bgr.jpeg') no-repeat;
  background-size: cover;
}

.case_3 {
  background: url('../assets/images/cases/5-6_bgr.jpeg') no-repeat;
  background-size: cover;
}

.case_4 {
  background: url('../assets/images/cases/5-7_bgr.jpeg') no-repeat;
  background-size: cover;
}

.case img, .info img {
  width: 100%;
}

.info {
  padding: 40px;
}

.make_case {
  padding: 40px 0;
}

.price_intro {
  text-align: center;
}

.price_free_intro {
  text-align: center;
  background: #00aae3;
  padding: 14px 0;
}

.price_intro_inline {
  text-align: left;
  display: inline-block;
  vertical-align: middle;
}

.price_intro img {
  width: 140px;
  margin-right: 20px;
  display: inline-block;
}

.camera_manual {
  padding: 40px 0;
  background: #f0f0f0;
}

.camera_manual .camera_manual_name, .how_works .how_works_name {
  display: block;
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #00aae3;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.camera_manual ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #787878;
  margin-bottom: 20px;
}

.camera_manual ul span {
  font-family: MyriadProSemibold, sans-serif;
  color: #00aae3;
}

.camera_manual p span {
  color: #00aae3;
}

.how_works {
  padding-top: 40px;
  text-align: center;
  background: #fff;
}

.how_works_list {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  background: #00aae3;
}

.how_works_list ol.list_1 {
  margin-left: 125px;
}

.how_works_list span {
  font-weight: bold;
}

.how_works_list ul {
  list-style: none;
}

.with_cloud {
  background: url('../assets/images/howitworks/2-5_bgr.jpeg') no-repeat center center;
  background-size: cover;
  padding: 29px 0 40px 0;
}

.with_cloud .with_cloud_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #00aae3;
  text-transform: uppercase;
  margin: 0;
}

.with_cloud p {
  list-style: none;
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #787878;
}

.account_credit {
  background: url('../assets/images/howitworks/2-6_bgr.jpeg') no-repeat center center;
  background-size: cover;
  padding: 29px 0 40px 0;
}

.account_credit .account_credit_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.account_credit p {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  font-size: 21px;
}

.broadcasting {
  background: #00aae3;
  padding: 29px 0 40px 0;
}

.broadcasting .broadcasting_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
}

.broadcasting p {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  font-size: 21px;
}

.hear_sound {
  background: url('../assets/images/howitworks/2-8_bgr.jpeg') no-repeat center center;
  background-size: cover;
  padding: 40px 0;
}

.hear_sound .hear_sound_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  color: #00aae3;
  text-transform: uppercase;
  margin: 0;
}

.hear_sound p {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  color: #787878;
}

.interface_detail {
  background: #f0f0f0;
  padding: 40px 0;
}

.interface_detail .interface_name {
  display: block;
  font-family: MyriadProSemibold, sans-serif;
  font-size: 26px;
  text-align: center;
  color: #00aae3;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.interface_detail p {
  font-family: MyriadProLight, sans-serif;
  text-align: center;
  font-size: 16px;
  color: #787878;
}

.contact_head {
  background: url('../assets/images/partners/8-1_bgr.jpeg') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 20px 0;
}

.contact_head .contact_name {
}

.hr_contact_head {
  margin: 20px auto;
  height: 1px;
  background: #00aae361;
  width: 25%;
}

.contact_head_name, .contact_tech_name {
  font-family: MyriadProSemibold, sans-serif;
  font-size: 21px;
  color: #00aae3;
}

.contact_head ul, .contact_tech ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  list-style: none;
  color: #787878;
}

.contact_head ul, .contact_tech ul {
  margin: 0;
}

.contact_tech {
  text-align: center;
  padding: 20px 0;
}

.contact_regions {
  text-align: center;
  background: #00aae3;
  color: #fff;
  min-height: 200px;
  padding: 20px 0;
}

.contact_regions_name {
  display: block;
  margin: 10px 0 20px 0;
  font-family: MyriadProSemibold, sans-serif;
}

.contact_regions h2 {
  color: #fff;
}

.contact_regions select {
  font-family: MyriadProLight, sans-serif;
  color: #fff;
  border-bottom: 1px dashed #fff;
  font-size: 21px;
  margin-top: 20px;
}

.contact_regions option {
  color: #00aae3;
  background: #fff;
}

.contact_regions ul li {
  list-style: none;
}

.contact_regions .region {
  margin: 20px 0;
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
}

.contact_regions .region ul {
  margin-bottom: 20px;
}

.europe_tel_1_2, .europe_tel_1_4, .cis_tel_1_2, .cis_tel_1_4 {
  line-height: 1.0;
  font-size: 18px;
}

.partner_head {
  background: url('../assets/images/partners/8-1_bgr-new.jpeg') no-repeat center center;
  background-size: cover;
  text-align: center;
  padding: 20px 0;
}

.partner_head .partner_head_1, .partner_head .partner_head_2 {
  display: block;
  color: #00aae3;
  font-size: 26px;
}

.partner_head .partner_head_1 {
  font-family: MyriadProSemibold, sans-serif;
  text-transform: uppercase;
}

.partner_head .partner_head_2 {
  font-family: MyriadProLight, sans-serif;
}

.partner_head p {
  color: #00aae3;
}

.partner_solution, .partner_b_model {
  padding: 40px 0;
}

.partner_solution img, .partner_b_model img {
}

.partner_solution ul, .partner_b_model ul {
  color: #787878;
  font-family: MyriadProLight, sans-serif;
  margin-left: 20px;
}

.agents_program {
}

.agents_program .container {
  padding: 40px 0;
  border-top: 1px solid #787878;
  border-bottom: 1px solid #787878;
  margin-bottom: 40px;
}

.about_agents {
  margin: 20px 0;
}

.partner_form {
  margin-top: 40px;
  background: #f0f5fa;
}

.cases_form {
  padding: 40px 0;
  background: #f0f5fa;
}

.hr_partner {
  margin: 20px auto;
  height: 1px;
  background: #00aae3;
  width: 85%;
}

.partner_invest {
  background: #00aae3;
  color: #fff;
  text-align: center;
  padding: 40px 0;
}

.partner_invest .invest_name {
  font-size: 26px;
  font-family: MyriadProSemibold, sans-serif;
  text-transform: uppercase;
}

.partner_invest ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 26px;
  list-style: none;
}

.be_partner {
  text-align: center;
  color: #808080;
  padding: 40px 0;
}

.be_partner ul {
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
  list-style: none;
}

.be_partner span {
  font-family: MyriadProSemibold, sans-serif;
  color: #a62189;
  text-transform: uppercase;
}

.btn_partner {
  text-align: center;
  margin-bottom: 40px;
}

.parent_hdr span {
  text-transform: uppercase;
}

.bold_hdr {
  font-family: MyriadProSemibold, sans-serif;
}

.light_hdr {
  font-family: MyriadProLight, sans-serif;
}

.magenta_hdr {
  color: #a62189;
}

.magenta_t {
  color: #a62189;
}

.blue_hdr {
  color: #00aae3;
}

.gray_t {
  color: #787878;
}

.white_hdr {
  color: #fff;
}

.yellow_hdr {
  color: #ffd100;
}

.blue_hdr {
  color: #00aae3;
}

.big_hdr {
  text-transform: uppercase;
}

.center_hdr {
  text-align: center;
}

.hdr_26 {
  font-size: 26px;
}

.hdr_28 {
  font-size: 28px;
}

.hdr_plus_11 {
  margin-top: 11px;
}

.hdr_22 {
  font-size: 22px;
}

.hdr_buy {
  margin-bottom: 120px;
}

.parent_ul {
  color: #787878;
  font-family: MyriadProLight, sans-serif;
  font-size: 21px;
}

.center_ul {
  text-align: center;
}

.no_ul ul {
  list-style: none;
}

.white_ul {
  color: #fff;
}

.ul_16 {
  font-size: 16px;
  margin: 10px 0;
}

.go_to_guide a {
  color: #787878;
}

.center_img {
  text-align: center;
}

.pad_img {
  margin: 40px 0;
}

.partner_ul {
  width: 71%;
}

.partner_solution .feature-1, .partner_solution .feature-2 {
  padding-left: 40px;
}

.div1, .div2 {
  display: inline-block;
}

.div2 {
  max-width: 78%;
}

.div1 {
  margin-right: 20px;
  margin-top: 7px;
  vertical-align: top;
  max-width: 20%;
}

.div3, .div4 {
  display: inline-block;
}

.div4 {
  max-width: 78%;
}

.div3 {
  margin-right: 20px;
  margin-top: 7px;
  vertical-align: top;
  max-width: 20%;
}

.partner_form input[type=text], .partner_form input[type=email], .partner_form textarea, .partner_form select, .cases_form input[type=text], .cases_form input[type=email], .cases_form textarea, .cases_form select, .abuse_form input[type=text], .abuse_form input[type=email], .abuse_form textarea, .abuse_form select {
  width: 100%;
}

.partner_form form, .cases_form form, .abuse_form form {
  width: 74%
}

.shop_form form {
  width: 100%
}

.partner_form input[type=submit], .cases_form input[type=submit], .abuse_form input[type=submit] {
  float: right;
  padding: 12.5px;
  border-radius: 6px;
}

.partner_form select, .cases_form select, .abuse_form select {
  font-style: italic;
  color: #787878;
  font-family: MyriadProLight, sans-serif;
  border-bottom: 1px dashed #787878;
}

.archive {
}

.share {
  padding: 40px 0;
}

.we_manufacture {
  background: #00aae3;
  padding: 40px 0;
}

.we_manufacture_img img {
  width: 90%;
}

.onvif {
  background-color: #eeebe5;
  padding: 40px 0 0 0;
}

.onvif img {
  margin-top: -40px;
}

.plugnplay {
  background: #00aae3;
}

.plugnplay ul {
  margin-bottom: 0;
}

.plugnplay_t {
  padding: 40px 0;
}

.plugnplay img {
  margin-left: -125px;
}

.shop_page {
  background: #fff;
  padding-top: 20px;
}

.product_data, .product_pdf {
  display: inline-block;
}

.product_price, .product_feature {
  text-align: center;
}

.block_tth {
  display: none;
}

#block_1080n {
  display: none;
}

.store_btn_block {
  text-align: center;
  margin-top: 20px;
}

.store_btn {
  display: inline-block;
  margin: 0 20px;
}

.parent_store_btn {
}

.store_btn img {
  margin-right: 20px;
}

.shop_product {
  margin: 40px 0;
}

.shop_product table {
  font-size: 16px;
}

.shop_img img {
  width: 100%;
}

.shop_img_adv img {
  width: 50%;
}

.shop_form textarea {
  border: 1px dashed #787878;
}

.shop_form_name {
  margin: 20px 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  background: #00aae3;
  opacity: 1;
}

.product_name {
  font-size: 21px;
  color: #00aae3;
  font-family: MyriadProSemibold, sans-serif;
}

.product_description ul, .product_description p, .shop .content {
  font-size: 16px;
  color: #787878;
  font-family: MyriadProLight, sans-serif;
}

.product_description ul {
  margin-left: 40px;
}

.shop .slider a {
  font-family: MyriadProLight, sans-serif;
  color: #00aae3;
  font-size: 21px;
  border-bottom: solid 1px #00aae3;
}

.shop .slider {
  margin: 20px 0;
}

.product_price, .product_total {
  font-size: 36px;
  color: #00aae3;
  font-family: MyriadProSemibold, sans-serif;
  padding-bottom: 10px;
}

.product_total {
  text-align: center;
  margin: 20px 0;
}

.product_total input {
  width: 60px;
}

.product_price input {
  width: 165px;
  text-align: center;
}

.product_price span, .product_total label, .product_total span {
  font-size: 21px;
  font-family: MyriadProLight, sans-serif;
}

.product_total label {
  text-transform: uppercase;
}

.product_total output {
  display: inline;
  font-size: 36px;
  font-family: MyriadProSemibold, sans-serif;
  color: #00aae3;
  margin-right: 5px;
}

.product_count {
  text-align: center;
}

.product_count input {
  font-family: MyriadPro, sans-serif;
  font-size: 21px;
  color: #00aae3;
  text-align: center;
  border-bottom: 1px dashed #00aae3;
  width: 100px;
  margin-bottom: 30px;
}

.shop_form input[type=text], .shop_form textarea {
  width: 80%;
}

.shop_form input[type=text], .shop_form input[type=email], .shop_form textarea, .partner_form input[type=text], .partner_form input[type=email], .partner_form textarea, .cases_form input[type=text], .cases_form input[type=email], .cases_form textarea, .abuse_form input[type=text], .abuse_form input[type=email], .abuse_form textarea {
  font-family: MyriadProLight, sans-serif;
  font-style: italic;
  font-size: 21px;
  margin: 10px 0;
  border-bottom: 1px dashed #787878;
}

.partner_form textarea, .cases_form textarea, .abuse_form textarea {
  padding: 5px 0 0 10px;
  border: 1px dashed #787878;
}

.shop_form input[type=text], .shop_form input[type=email], .shop_form textarea {
  width: 100%;
}

.cases_form_inline {
  width: 30%;
  display: inline-block;
  padding: 0 20px;
  vertical-align: bottom;
}

.cases_form_btn {
  width: 100%;
  display: inline-block;
}

.shop_form_btn {
  text-align: center;
}

.shop_form_btn span {
  line-height: 50px;
}

.shop_form_btn button {
  margin: 10px auto;
  color: #646464;
}

.shop_form_btn button:hover {
  color: #fff;
}

.shop_form_btn i {
  line-height: 50px;
  float: right;
}

.g-recaptcha {
  margin-bottom: 10px;
}

.shop_form select, .partner_form select, .cases_form select, .abuse_form select {
  font-style: italic;
  color: #787878;
  font-family: MyriadProLight, sans-serif;
  border-bottom: 1px dashed #787878;
  opacity: 0.7;
}

.shop_form .shop_send {
  text-align: center;
  margin-top: 20px;
}

.abuse_form {
  padding: 40px 0;
}

.shop_form, .partner_form {
  padding: 30px 0;
}

.input_field {
  color: #787878;
}

td, th {
  padding: 0 5px;
  border: 1px solid #ccc;
}

.separator {
  background: url("../assets/images/shop/dot-separator.png") repeat-x;
  height: 3px;
}

.shop_btn, .request_btn {
  margin-bottom: 20px;
  text-align: center;
  width: 150px;
}

.magic {
  background: url('../assets/images/11_bgr.jpeg') no-repeat center center;
  background-size: cover;
  padding: 40px 0;
}

ul {
  padding: 0 !important;
}

.archive .parent_ul {
  padding-top: 20px;
}

.wview {
  background: url('../assets/images/12_bgr.jpeg') no-repeat center center;
  background-size: cover;
  padding: 40px 0;
}

.screenshots-intro {
  padding: 170px 0 100px 0;
  background-color: #f6f7f9;
}

.screenshots-intro h1 {
  margin-bottom: 20px;
  color: #24374b;
  font-weight: 400;
  font-size: 22px;
}

.screenshots-intro p {
  margin-bottom: 25px;
  color: #778899;
}

.screenshots ul {
  width: 100%;
}

.screenshots ul li {
  min-height: 100%;
  list-style: none;
}

//button:focus, input:focus, a:focus {
//  outline: none !important;
//  box-shadow: none !important;
//  border: 0 !important;
//}

.screenshots figure {
  position: relative;
  overflow: hidden;
}

.screenshots figure img {
  width: 100%;
  height: 100%;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.screenshots figure:hover img, .screenshots figure:focus img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.screenshots figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 97, 132, 0.25);
  text-align: center;
  font-size: 15px;
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.screenshots figcaption a {
  color: #fff
}

.screenshots figcaption a:hover, .screenshots figcaption a:focus {
  color: #73d0da
}

.screenshots figure:hover figcaption, .screenshots figure:focus figcaption {
  opacity: 1
}

.visible {
  opacity: 1
}

.screenshots figure.cs-hover figcaption {
  opacity: 1
}

.screenshots figcaption i {
  font-size: 35px
}

.screenshots figcaption p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.screenshots figcaption .caption-content {
  position: absolute;
  bottom: 10px;
  left: 30px;
  margin-top: -40px;
  margin-left: -100px;
  width: 200px;
  -webkit-transform: translate(0px, 15px);
  -ms-transform: translate(0px, 15px);
  transform: translate(0px, 15px);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.screenshots figure:hover figcaption .caption-content, .screenshots figure:focus figcaption .caption-content {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.download {
  padding: 120px 0;
  background-color: #3f6184;
}

.download h1 {
  margin: 0 0 15px 0;
  color: #fff;
  font-weight: 400;
  font-size: 40px;
}

#form-messages {
  display: none;
}

footer {
  background: #7b838c;
  padding: 10px 0
}

footer p {
}

footer ul {
}

footer li {
  font-family: MyriadProLight, sans-serif;
  font-size: 16px;
  float: left;
  margin-right: 5%;
  list-style: none;
  text-transform: uppercase;
}

footer li a {
  text-decoration: none;
  color: #fff;
}

footer li a:hover {
  color: white;
}

footer li:last-child {
  margin-right: 0
}

footer .visa {
}

footer img {
  margin-left: 20px;
  width: 50px;
  float: right;
}

.privacy_policy, .terms_of_use {
  margin-bottom: 40px;
  font-family: MyriadProLight, sans-serif;
  color: #787878;
  font-size: 21px;
}

.privacy_policy span, .terms_of_use span {
  color: #00aae3;
}

.privacy_policy h1, .terms_of_use h1 {
  text-align: center;
  margin-bottom: 20px;
}

.privacy_policy ul, .terms_of_use ul {
  list-style: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 82, 113, 0.95);
}

.overlay nav {
  position: relative;
  top: 50%;
  height: 60%;
  text-align: center;
  font-size: 54px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.overlay ul {
  position: relative;
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.overlay ul li {
  display: block;
  float: left;
  margin-right: 10px;
}

.overlay ul li:last-child {
  margin-right: 0px
}

.overlay ul li i {
  display: block;
  margin-bottom: 10px;
  font-size: 40px;
}

.overlay ul li a {
  display: block;
  padding: 57px 0 0 0;
  min-width: 140px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 15px;
}

.fa-it-works {
  background: url('../assets/images/Sandwich-1-passive.png') no-repeat top center;
}

.fa-adv {
  background: url('../assets/images/Sandwich-0-passive.png') no-repeat top center;
}

.fa-online {
  background: url('../assets/images/Sandwich-2-passive.png') no-repeat top center;
}

.fa-prices {
  background: url('../assets/images/Sandwich-3-passive.png') no-repeat top center;
}

.fa-cases {
  background: url('../assets/images/Sandwich-4-passive.png') no-repeat top center;
}

.fa-shop {
  background: url('../assets/images/Sandwich-5-passive.png') no-repeat top center;
}

.fa-partners {
  background: url('../assets/images/Sandwich-6-passive.png') no-repeat top center;
}

.fa-contacts {
  background: url('../assets/images/Sandwich-7-passive.png') no-repeat top center;
}

.fa-it-works:hover {
  background: url('../assets/images/Sandwich-1-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-adv:hover {
  background: url('../assets/images/Sandwich-0-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-online:hover {
  background: url('../assets/images/Sandwich-2-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-prices:hover {
  background: url('../assets/images/Sandwich-3-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-cases:hover {
  background: url('../assets/images/Sandwich-4-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-shop:hover {
  background: url('../assets/images/Sandwich-5-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-partners:hover {
  background: url('../assets/images/Sandwich-6-active.png') no-repeat top center;
  color: #007fa9;
}

.fa-contacts:hover {
  background: url('../assets/images/Sandwich-7-active.png') no-repeat top center;
  color: #007fa9;
}

.overlay ul li a span {
  display: block;
  padding: 17px 0;
  border: 2px solid #fff;
}

.fa-it-works span:hover, .fa-online span:hover, .fa-prices span:hover, .fa-cases span:hover, .fa-shop span:hover, .fa-partners span:hover, .fa-contacts span:hover, .fa-adv span:hover {
  border-color: #007fa9;
}

.overlay-boxify {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, visibility 0s 0.5s;
}

.overlay-boxify.open {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  z-index: 999;
}

.overlay-boxify nav ul li {
  opacity: 0;
  -webkit-transform: translate3d(0, -80px, 0);
  transform: translate3d(0, -80px, 0);
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
  transition: transform 0.5s, opacity 0.5s;
}

.overlay-boxify.open nav ul:first-child li:first-child {
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
}

.overlay-boxify.open nav ul:first-child li:nth-child(2) {
  -webkit-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.overlay-boxify.open nav ul:nth-child(2) li:first-child {
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
}

.overlay-boxify.open nav ul:nth-child(2) li:nth-child(2) {
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.overlay-boxify.open nav ul li {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.wp1, .wp2, .wp3, .wp4, .wp5, .wp6 {
  visibility: hidden
}

.bounceInLeft, .bounceInRight, .fadeInUp, .fadeInUpDelay, .fadeInDown, .fadeInUpD, .fadeInLeft, .fadeInRight, .bounceInDown {
  visibility: visible
}

.delay-05s {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.homepage-hero-module {
  margin-top: 7px;
  border-right: none;
  border-left: none;
  position: relative;
}

.no-video .video-container video,
.touch .video-container video {
  display: none;
}

.no-video .video-container .poster,
.touch .video-container .poster {
  display: block !important;
}

.video-container {
  z-index: 0;
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 345px;
  width: 615px;
  overflow: hidden;
}

.video-container2 {
  z-index: 0;
  position: relative;
  top: 0%;
  left: 0%;
  height: 295px;
  overflow: hidden;
}

.video-container .poster img, .video-container2 .poster img {
  width: 100%;
  bottom: 0;
  position: absolute;
}

.video-container .filter, .video-container2 .filter {
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
}

img {
  max-width: 100%;
}

.video-container .title-container {
  z-index: 1000;
  position: absolute;
  width: 100%;
  text-align: center;
  color: #fff;
  background: url('../assets/images/devices-bg.png') no-repeat;
  height: 345px;
}

.video-container2 .title-container2 {
  z-index: 1000;
  position: absolute;
  width: 100%;
}

.video-container .description .inner, .video-container2 .description .inner {
  font-size: 1em;
  width: 45%;
  margin: 0 auto;
}

.video-container .link, .video-container2 .link {
  position: absolute;
  bottom: 3em;
  width: 100%;
  text-align: center;
  z-index: 1001;
  font-size: 2em;
  color: #fff;
}

.video-container .link a, .video-container2 .link a {
  color: #fff;
}

.video-container video, .video-container2 video {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.video-container video.fillWidth, .video-container2 video.fillWidth {
  width: 100%;
}

.video-container-mob video, .video-container-mob video {
  width: 100%;
}

.video_2_text {
  margin: 40px;
}

.video_2 {
  position: relative;
  bottom: -76px;
}

.video-container-laptop .title-container-laptop {
  z-index: 1000;
  position: absolute;
  width: 100%;
  text-align: center;
  color: #fff;
  background: url('../assets/images/laptop-v2.png') no-repeat;
  height: 410px;
}

.video-container-laptop {
  position: relative;
  bottom: 0%;
  left: 0%;
  height: 408px;
  width: 720px;
  overflow: hidden;
}

#languages {
  margin-left: 10px;
  margin-right: 5px;
}

.language-wrapper {
  display: inline-block;
  margin-right: 10px;
}

#languages_panel {
  z-index: 2;
  margin-right: 17px;
}

@media screen and (max-width: 640px) {
  #languages_panel {
  }
}

.language-dropdown-closed {
  display: none;
}

.language-dropdown-opened {
}

#languages_panel nav > ul {
  margin: 0;
  height: 29px;
  background-color: transparent;
  padding-left: 0px;
}

#languages_panel nav > ul:after {
  content: "";
  clear: both;
  display: block;
}

.dropdown-with-border-radius {
  border-radius: 4px;
}

.dropdown-without-border-radius {
  border-radius: 4px 4px 0px 0px;
}

#languages_panel nav ul > li {
}

.current_language_hover {
}

#languages_panel nav ul li:hover > a {
  color: #787878;
  text-decoration: none;
  display: block;
  text-align: center;
}

#languages_panel > nav > ul > li > ul > li:hover > a {
  color: #00afe8;
}

#languages_panel nav ul li a {
  font-size: 14px;
  color: #787878;
  text-decoration: none;
  width: 92px;
  display: block;
  height: 25px;
  text-align: center;
  text-transform: uppercase;
}

#languages_panel nav ul li a.current-language.active {
  text-decoration: none;
  display: block;
  width: 92px;
  text-align: center;
  color: #00afe8;
  border-bottom: #00afe8 dashed 1px;
}

#languages_panel > nav > ul > li > ul > li > a {
  line-height: 220%;
}

#current_language {
  cursor: pointer;
  border-bottom: transparent dashed 1px;
}

#current_language:hover {
  border-bottom: #787878 dashed 1px;
}

#languages_panel nav ul ul {
  background-color: #ffffff;
  margin-top: 1px;
  padding: 0;
  position: absolute;
  top: 100%;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 2px 2px 3px 1px #777;
}

#languages_panel nav ul ul li {
  float: none;
}

#languages_panel nav ul ul li:last-child {
  padding-bottom: 5px;
}

#languages_panel nav ul ul li a {
}

#glyphicon-dropdown {
  font-size: 12pt;
  color: #00afe8;
  vertical-align: middle;
}

#languages_panel > nav > ul > li {
  position: relative;
  z-index: 5;
}

@font-face {
  font-family: MyriadPro;
  font-weight: normal;
  src: url('../assets/font/MyriadPro-Regular_0.otf');
}

@font-face {
  font-family: MyriadProSemibold;
  font-weight: normal;
  src: url('../assets/font/MyriadPro-Semibold_0.otf');
}

@font-face {
  font-family: MyriadProLight;
  font-weight: normal;
  src: url('../assets/font/MyriadPro-Light.otf');
}

a.cd-top {
  z-index: 10;
}

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #787878 url("../assets/images/cd-top-arrow.svg") no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}

.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}

.cd-top.cd-is-visible {
  visibility: visible;
  opacity: .5;
}

.cd-top.cd-fade-out {
  opacity: .5;
}

.no-touch .cd-top:hover {
  background-color: #787878;
  opacity: .8;
}

@media only screen and (min-width: 768px) {
  .cd-top {
    right: 100px;
    bottom: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 50px;
    width: 50px;
    right: 100px;
    bottom: 20px;
  }
}

.cloud-for-any-brand {
  background: url('../assets/images/9_bg.jpeg') no-repeat;
  background-size: cover;
  background-position: center, center;
  padding: 40px;
}

.cloud-for-any-brand ul, .cloud-for-any-brand li {
  padding: 15px 0;
}

.onvif ul {
  padding: 15px 0;
}

.onvif li {
  padding: 5px 0;
}

.onvif hr {
  background-color: #00aae3;
  height: 2px;
  border: none;
  width: 66%;
}

section.showcase .block-buy {
  margin-bottom: 40px;
}

@media only screen and (max-width: 991px) {
  .cloud-for-any-brand {
    background-position-x: left;
  }
  .onvif hr {
    width: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cloud-for-any-brand {
    padding: 30px;
  }
  .cloud-for-any-brand ul, .cloud-for-any-brand li {
    padding: 10px 0;
  }
}

.box {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}

.dropdown-menu {
  overflow: hidden;
  right: 0 !important;
  left: -80px !important;
  min-width: 100px;
  background-color: #a62189;
  border-radius: 20px;

  li {
    a {
      padding: 7px 15px;
      color: white;
    }
  }
}

.dropdown-toggle {
  &:after {
    display: none;
  }
}

.dropdown {
  background-color: transparent !important;
  padding: 0 !important;
  border: 0 !important;

  a {
    background-color: #a62189;
    padding: 14.5px 24px !important;
    color: white;
    border-radius: 40px;
    text-decoration: none;
  }
}